import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby"; // Import useStaticQuery
import Page from "../../components/Page/Page";
import TopBar from "../../components/TopBar/TopBar";
import LatestArticle from "../../components/LatestArticle/LatestArticle";
import Footer from "../../components/Footer/Footer";
import Section from "../../components/Section/Section";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import BlogSearch from "../../components/BlogSearch/BlogSearch";
import { useLocation } from "@reach/router";

const BlogSearchPage = () => {
  const location = useLocation();

  const searchQuery = new URLSearchParams(location.search).get("query");

  const [searchTerm, setSearchTerm] = useState(searchQuery);

  const data = useStaticQuery(graphql`
    {
      allContentfulArticle {
        edges {
          node {
            id
            title
            slug
            metaTitle
            metaDescription {
              childMarkdownRemark {
                html
              }
            }
            thumbnailImageUrl
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);

  const allArticles = data.allContentfulArticle.edges;
  const [searchResults, setSearchResults] = useState(allArticles);

  useEffect(() => {
    if (!searchTerm || searchTerm.length === 0) {
      navigate("/blog");
    } else {
      console.log("filter all articles", searchTerm);
      // Perform filtering using JavaScript
      const filteredArticles = allArticles.filter(
        (article) =>
          article.node.title &&
          article.node.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setSearchResults(filteredArticles);
    }
  }, [allArticles, searchTerm]);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div>
        <Section>
          <SectionHeading>SEARCH RESULTS</SectionHeading>
          <BlogSearch
            onSearch={(newSearchTerm) => setSearchTerm(newSearchTerm)}
          />
          <div className="blogs">
            {searchResults.map((article) => (
              <div className="blog-post" key={article.node.id}>
                <LatestArticle latestArticle={article.node} />
              </div>
            ))}
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default BlogSearchPage;
