import * as React from "react";
import clsx from "clsx";
import { Link } from "gatsby";

import "./LatestArticle.css";

export default function LatestArticle({ latestArticle }) {
  console.log("latestArticle.slug", latestArticle.slug);
  return (
    <Link to={`/${latestArticle.slug}`} className="latest-article-link">
      <div className={clsx("latest-article")}>
        <div
          className="latest-article-image-section"
          style={{ backgroundImage: `url(${latestArticle.thumbnailImageUrl})` }}
        />
        <div className="latest-article-content-section">
          <div className="latest-article-main">
            {latestArticle.title && (
              <div className="latest-article-title">
                {latestArticle.title.toUpperCase()}
              </div>
            )}
            {latestArticle.description &&
              latestArticle.description.childMarkdownRemark && (
                <div
                  className="latest-article-tagline"
                  dangerouslySetInnerHTML={{
                    __html: latestArticle.description.childMarkdownRemark.html,
                  }}
                />
              )}
          </div>
        </div>
      </div>
    </Link>
  );
}
